import React, { useState } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import JSZip from 'jszip';

// Create a new axios instance with retry configuration
const axiosInstance = axios.create();
axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000; // wait 1s, 2s, 3s between retries
  },
  retryCondition: (error) => {
    // Retry on network errors or 5xx server errors
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status >= 500;
  },
});

const ProgressBar = ({ stage }) => {
  const stages = ['Uploading', 'Organizing', 'Analyzing', 'Done'];
  const currentIndex = stages.indexOf(stage);

  return (
    <div className="progress-modal">
      <div className="progress-bar">
        {stages.map((s, index) => (
          <div
            key={s}
            className={`progress-stage ${index <= currentIndex ? 'active' : ''} ${s === 'Done' ? 'done' : ''}`}
          >
            {s}
            {s === 'Done' && index <= currentIndex && <span className="checkmark">✓</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

const Upload = ({ inviteCode }) => {
  const [candidateName, setCandidateName] = useState('');
  const [outputFile, setOutputFile] = useState(null);
  const [codeFile, setCodeFile] = useState(null);
  const [validationResult, setValidationResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadStage, setUploadStage] = useState(null);
  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setCandidateName(e.target.value);
  };

  const handleOutputChange = (e) => {
    setOutputFile(e.target.files[0]);
  };

  const handleCodeChange = (e) => {
    setCodeFile(e.target.files[0]);
  };

  const validateOutputFile = async (file) => {
    const text = await file.text();
    const lines = text.split('\n');
    let isValid = true;

    for (let line of lines) {
      if (line.trim() === '') continue;
      try {
        const json = JSON.parse(line);
        if (typeof json.id !== 'string' || typeof json.customer_id !== 'string' || typeof json.accepted !== 'boolean') {
          isValid = false;
          break;
        }
      } catch (e) {
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  const validateZipFile = async (file) => {
    const zip = new JSZip();
    try {
      const contents = await zip.loadAsync(file);
      for (let filePath in contents.files) {
        const file = contents.files[filePath];
        if (file.dir) {
          if (filePath.startsWith('.git/') || filePath.startsWith('.gradle/') || filePath.startsWith('.')) {
            return `Rejected: Archive contains a hidden directory (${filePath})`;
          }
        } else {
          if (filePath === 'input.txt' || filePath === 'output.txt') {
            return `Rejected: Archive contains a forbidden file (${filePath})`;
          }
          if (file.uncompressedSize > 500 * 1024) {
            return `Rejected: Archive contains a file larger than 500KB (${filePath})`;
          }
          if (/\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/i.test(filePath)) {
            return `Rejected: Archive contains an image file (${filePath})`;
          }
        }
      }
      return null; // No issues found
    } catch (error) {
      return `Error processing zip file: ${error.message}`;
    }
  };

  const simulateStage = (stage, duration) => {
    return new Promise((resolve) => {
      setUploadStage(stage);
      setTimeout(resolve, duration);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!candidateName || !outputFile || !codeFile) {
      alert('Please provide your name and upload both files.');
      return;
    }
  
    if (outputFile.size > 5 * 1024 * 1024 || codeFile.size > 20 * 1024 * 1024) {
      alert('File size exceeds the limit.');
      return;
    }
  
    setLoading(true);
    setValidationResult(null);
    setUploadStage('Uploading');
  
    const isOutputValid = await validateOutputFile(outputFile);
    if (!isOutputValid) {
      setValidationResult('Output file is invalid.');
      setLoading(false);
      setUploadStage(null);
      return;
    }
  
    const zipValidationResult = await validateZipFile(codeFile);
    if (zipValidationResult) {
      setValidationResult(zipValidationResult);
      setLoading(false);
      setUploadStage(null);
      return;
    }

    const formData = new FormData();
    const uuid = uuidv4();
    formData.append('uuid', uuid);
    formData.append('inviteCode', inviteCode);
    formData.append('candidateName', candidateName);
    formData.append('outputFile', outputFile);
    formData.append('codeFile', codeFile);
  
    try {
      await simulateStage('Uploading', 2000);
      await simulateStage('Organizing', 1500);
      await simulateStage('Analyzing', 2500);

      const response = await axiosInstance.post('https://notion-integration-worker.kris-772.workers.dev/submit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        await simulateStage('Done', 1000);
        navigate('/thank-you');
      } else {
        console.error('Unexpected response from server:', response);
        setValidationResult(`Submission failed: ${response.data.message}`);
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response from server:', error.response);
        setValidationResult(`Submission failed: ${error.response.data.message}`);
      } else if (error.request) {
        console.error('No response received from server:', error.request);
        setValidationResult('Submission failed: No response from server.');
      } else {
        console.error('Error during submission:', error.message);
        setValidationResult(`Submission failed: ${error.message}`);
      }
    } finally {
      setLoading(false);
      setUploadStage(null);
    }
  };
  
  return (
    <div className="form-container">
      <h3 className="section-title">Upload Your Solution</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Candidate Name:</label>
          <input
            type="text"
            value={candidateName}
            onChange={handleNameChange}
            required
          />
        </div>
        <div>
          <label>Upload output.txt:</label>
          <input
            type="file"
            accept=".txt"
            onChange={handleOutputChange}
            required
          />
        </div>
        <div>
          <label>Upload your code (e.g., .zip):</label>
          <input
            type="file"
            accept=".zip,.tar.gz,.tar,.rar"
            onChange={handleCodeChange}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {uploadStage && <ProgressBar stage={uploadStage} />}
      {validationResult && <p>{validationResult}</p>}
    </div>
  );
};

export default Upload;
